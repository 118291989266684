@import '@styles/mixins/_flex-alignment.scss';
@import '@styles/mixins/_responsive.scss';

.cost-extract {
  padding-bottom: 20px;

  @include responsive-desktop-min {
    padding-right: 0px;
    padding-left: 50px;
    padding-bottom: 70px;
  }

  .period-info {
    @include responsive-desktop-min {
      display: none;
    }
  }

  .summary {
    display: none;

    @include responsive-desktop-min {
      display: block;
      flex-grow: 0.3;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include responsive-desktop-min {
      flex-direction: row;
      margin-top: 45px;
    }
  }

  div.values {
    width: 100%;

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li {
      display: flex;
      justify-content: space-between;
      font-size: 0.85rem;
      padding: 0px 20px;

      @include responsive-desktop-min {
        padding: 0px 80px;
      }

      &:first-child {
        font-weight: 600;
        background-color: $white-1;

        @include responsive-desktop-min {
          padding: 0px 60px;
        }
      }

      span {
        color: $blue-5;
      }

      span:first-child {
        padding-left: 10px;
      }

      span:last-child {
        font-weight: 600;
      }
    }

    &:not(:last-child) {
      ul {
        padding-bottom: 10px;
      }
    }
  }

  .cost-box {
    &__body {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__footer {
      @include responsive-desktop-min {
        padding: 5px 80px 5px 60px;
      }
    }

    &:not(:last-child) {
      &::after {
        content: ' ';
        border-bottom: 1px solid $gray;
        width: 95%;
        margin: auto;
      }
    }
  }

  .cost-list {
    @include responsive-desktop-min {
      flex-grow: 1;
      margin: 0px 40px;
    }

    .empty-msg-data {
      margin-top: 27px;
      padding: 15px 30px;
      font-size: 0.9rem;
      font-weight: 500;
      border-radius: 5px;
      box-shadow: 0px 4px 6px rgba(20, 70, 150, 0.15);
      background-color: white;
    }

    &__zone {
      display: flex;
      flex-direction: column;

      &:first-child {
        @include responsive-desktop-min {
          .title {
            margin-top: 0px;
          }
        }
      }

      .title {
        color: $blue;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom: 10px;
        margin-top: 20px;
        padding-left: 10px;
      }
    }

    .list {
      border-radius: 5px;
      box-shadow: 0px 4px 6px rgba(20, 70, 150, 0.15);
      background-color: white;

      .empty-msg-base {
        margin: 15px 30px;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }

  .filters {
    display: none;
    padding: 0px 0px 10px 0px;
    color: $blue;

    @include responsive-desktop-min {
      display: flex;

      .form-group {
        flex-grow: 1;
      }
    }
  }
}