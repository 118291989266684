.processing {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1300;
  /* Sit on top */
  //padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */

  .vertical-center {
    margin: 0;
    position: fixed;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}