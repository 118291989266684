.items {
  width: 100%;
  position: relative;
  padding: 30px 10px 30px 5px;

  .title {
    color: $blue;
    font-size: 26px;
    font-weight: 700;
    padding-bottom: 10px;
  }

  @include responsive-mobile {
    .title {
      font-size: 24px;
    }
  }

  .list {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    border: 0.6px solid #798bc1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    border-radius: 15px;
    box-sizing: border-box;
  }

  .selected {
    color: #00b4db;
    line-height: 158%;
    font-weight: 400;
    border-bottom: 4px solid #00b4db;
    cursor: pointer;
  }

  .notSelected {
    color: #808080;
    margin-bottom: 10px;
    line-height: 158%;
    font-weight: 400;
    cursor: pointer;
  }

  .divider {
    background-color: $blue;
    width: 1px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .add-item {
    margin-top: 46.5px;
    position: relative;
    width: 100%;

    .drop-down-item {
      border-radius: 15px;
      box-shadow: 0px 4px 4px 1px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
      background-color: #fbfbfb;
      transform: translateY(-20px);

      .custom-space {
        padding: 10px;
        margin-top: 10px;

        .form-check {
          padding-left: 0 !important;
        }
      }
    }

    .add-button {
      padding: 22px;
      border-radius: 15px;
      box-shadow: 0px 4px 4px 1px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
      cursor: pointer;
      z-index: 1;

      .font {
        color: $blue;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .large-button {
      width: 100%;
      height: 50px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .items-container {
    border-radius: 5px;
    margin-top: 12px;
    position: relative;

    .item-details {
      display: flex;
      justify-content: space-between;

      &:hover {
        .item-position {
          opacity: 1;
        }
      }

      .container-indicator {
        display: flex;
        align-items: center;

        .fixed-height {
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;
        }

        .indicatorPositive {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #4c65ad;
          border-radius: 15px;
          font-size: 12px;
          color: white;
          width: 75px;
          text-align: center;
          margin-right: 40px;
          max-height: 20px;
        }

        .indicatorNegative {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #8F9FCB;
          border-radius: 15px;
          font-size: 12px;
          color: white;
          width: 75px;
          text-align: center;
          margin-right: 40px;
          max-height: 20px;
        }
      }

      .name {
        color: $blue;
        font-weight: 700;
        font-size: 16.5px;
        max-width: 204px;
      }

      .name-grey {
        color: #666666;
        font-weight: 700;
        font-size: 16.5px;
        max-width: 204px;
      }

      @include responsive-mobile {
        .name {
          font-size: 14px;
        }

        .name-grey {
          font-size: 14;
        }
      }

      .item-position {
        position: absolute;
        right: 0;
        z-index: 5;
        opacity: 0;

        .tooltip-icon {
          display: inline;
          position: absolute;
        }

        &:hover:after {
          position: absolute;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          justify-content: center;
          background: #e9ecf5;
          border-radius: 4px;
          color: $blue;
          content: attr(data-tooltip);
          margin: 0px auto 0;
          font-size: 13px;
          padding: 5px;
          width: 90px;
          right: -33px;
          top: 30px;
          text-align: center;
        }

        &:hover:before {
          border: solid;
          border-color: #e9ecf5 transparent;
          border-width: 12px 6px 0 6px;
          content: '';
          left: 5px;
          bottom: 43px;
          top: 85%;
          position: absolute;
          transform: rotate(180deg);
        }
      }
    }
  }

  .bg-grey {
    background-color: #f6f6f6;
  }

  .card-body {
    padding-left: 13px !important;
    padding-right: 13px !important;

    &:hover {
      .item-position {
        opacity: 1;
      }

      .name {
        color: #00b4db;
      }

      .name-grey {
        color: #00b4db;
      }

      .rounded-indicator {
        background-color: #00b4db;
      }
    }
  }
}