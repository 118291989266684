@import '@styles/settings/_colors.scss';
@import '@styles/mixins/_responsive.scss';

.edit-template {
  width: 100%;
  padding: 15px;

  &_title {
    font-size: 25px;
    line-height: 28.56px;
    color: $blue;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &_buttons {
    display: flex;
  }

  .list-container {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $secondary;
    margin-bottom: 10px;

    .template-title {
      font-size: 16px;
      font-weight: 700;
      color: #4f4f4f;
    }

    .download-btn {
      width: 100px;
      margin-bottom: 0px;
      padding: 10px 0px;
      font-size: 13px;
      line-height: 11.72px;
    }

    .margin-icon-eye {
      margin-right: 33px;
      margin-left: 33px;
    }
  }

  @include responsive-tablet-low-resolution {
    &_title {
      font-size: 25px;
      line-height: 25px;
    }

    .list-container {
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      .template-title {
        font-size: 17px;
      }
    }

    .mobile-size {
      width: 25px;
    }
  }

  @include responsive-mobile {
    &_title {
      font-size: 24px;
      line-height: 25px;
      margin-bottom: 20px;
      margin-right: 0px;
    }

    .list-container {
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      .template-title {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    &_buttons {
      justify-content: center;
      margin-top: 10px;
    }

    .mobile-size {
      width: 22px;
    }
  }

  .template-select {
    margin-top: 30px;
  }
}