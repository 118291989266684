$primary: $blue;
$primary-color: $blue;

.cost-summary-page {
  @include responsive-mobile {
    padding-left: 0px;
  }

  &__content {
    padding-top: 10px;
  }

  &__alerts {
    @include flex-center;
  }

  &__actions {
    @include flex(flex-end, center);

    .action {
      &__btn {
        font-size: 1rem;
        padding: 0.8rem 2.2rem;
      }
    }
  }

  &__wrapper-paginate {
    padding: 10px 0px;
  }

  &__wrapper-breadcrumb {
    color: $primary;
    background-color: white;
    border-bottom: 1px solid #e5e5e5;
    padding: 50px 0px 0px 0px;
  }

  &__wrapper-grid {
    .download {
      text-align: right;

      a,
      a:visited,
      a:active {
        color: black;
        text-decoration: none;
      }
    }
  }

  &__management {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__wrapper-filters {
    width: 53%;

    @include responsive-mobile {
      width: 100%;
    }

    .filters {
      &__controls {
        @include flex(flex-start, flex-end);

        .form-group {
          margin-bottom: 0;
          margin-right: 20px;
        }

        @include responsive-mobile {
          width: 100%;
          flex-direction: column;
          align-items: normal;
        }
      }

      .custom-select {
        text-transform: capitalize;
        max-width: 200px;
        margin-right: 20px;

        @include responsive-mobile {
          max-width: 100%;
          margin-bottom: 10px;
        }

        &:not(:last-child) {
          @include responsive-mobile {
            margin-right: 0px;
          }
        }
      }

      &__btn {
        width: 100px;
        @include flex(space-between, center);

        &--w-2x {
          width: 150px;
        }

        .white-color {
          border-color: white;

          &.small {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }

  &__wrapper-total-bar {
    padding: 10px 0px;

    .total-bar {
      background-color: $primary-color;
      color: white;
      padding: 3px 23px;
      border-radius: 10px;
      @include flex(flex-start, center);

      @include responsive-mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &__item {
        padding-right: 45px;
        margin-right: 18px;
        border-right: 1px solid white;

        &--right {
          margin-left: auto;
        }

        &:nth-last-child(-n + 2) {
          padding-right: 0px;
          margin-right: 0px;
          border-right-width: 0px;
        }

        @include responsive-mobile {
          border-right-width: 0px;
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }
}