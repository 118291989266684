.wide-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-blue {
    width: 100px;
  }
}