@import '~uikit-bx/src/styles/main.scss';
@import '~uikit-bx/src/styles/sidenav';

/** SETTINGS **/
@import './settings/global';
@import './settings/colors';
@import './settings/fonts';

/** MIXINS **/
@import './mixins/flex-alignment';
@import './mixins/responsive';

/** GLOBAL **/
@import 'site';

@import './sidenav.scss';

/** PAGES **/
@import './pages/cost-summary';
@import './pages/cost-preview';
@import './pages/cost-extract';
@import './pages/items-extract';
@import './pages/preview';
@import './pages/finish';
@import './pages/period-history.scss';
@import './pages/maintainers-home.scss';
@import './pages/discount-prioritization.scss';
@import './pages/edit-template.scss';
@import './pages/items.scss';
@import './pages/filter-providers.scss';
@import './pages/transfer-settlements.scss';
@import './pages/transfer-history.scss';