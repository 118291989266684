.transfer-history {

  .custom-button {
    height: 50px;
    width: 100%;
  }

  .not-found-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .close-alert {
    display: flex;
    justify-content: flex-end;
  }
}