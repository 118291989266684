@import '@styles/mixins/_responsive.scss';

.filter-providers {
  padding-bottom: 100px;

  @include responsive-desktop-min {
    padding-right: 0px;
    padding-left: 50px;
    padding-bottom: 70px;
  }

  .period-info {
    @include responsive-desktop-min {
      display: none;
    }
  }

  .filter-options {
    p {
      color: $blue;
      font-size: 15px;
      font-weight: 700;
      line-height: 17, 58px !important;
      text-transform: uppercase;
    }

    @include responsive-desktop-min {
      display: block;
      flex-grow: 0.3;
      height: 100%;
      background: white;
      border-radius: 5px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      padding: 30px 30px 15px 30px;
    }

    .custom-button {
      width: 175px;
      padding: 15px 0px;
    }

    .space-rigth {
      margin-right: 10px;
    }

    .space-left {
      margin-left: 10px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include responsive-desktop-min {
      flex-direction: row;
    }
  }

  div.values {
    width: 100%;

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li {
      display: flex;
      justify-content: space-between;
      font-size: 0.85rem;
      padding: 3px 35px;

      &:nth-child(odd) {
        background-color: $white-1;
      }

      span {
        color: $blue-5;
      }

      span:last-child {
        font-weight: 600;
      }
    }

    &:not(:last-child) {
      ul {
        padding-bottom: 10px;
      }
    }
  }

  .items-manager {
    button.btn {
      width: 100%;
      font-size: 1.1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      margin: 10px 0px;

      &:first-child {
        background-color: white !important;

        img {
          width: 25px;
        }
      }

      &:last-child {
        img {
          width: 30px;
        }
      }

      span {
        margin: auto;
      }
    }
  }

  .provider-cost-box {
    border-bottom-width: 0px;

    &__body {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__footer {
      @include responsive-desktop-min {
        padding: 5px 30px 5px 30px;
      }
    }

    &:not(:last-child) {
      &::after {
        content: ' ';
        border-bottom: 1px solid $gray;
        width: 95%;
        margin: auto;
      }
    }
  }

  &__list {
    @include responsive-desktop-max {
      margin-top: 2.2rem;
    }

    @include responsive-desktop-min {
      flex-grow: 1;
      margin: 0px 40px;
    }

    .result {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &__text {
        font-size: 17px;
        color: $blue;
        font-weight: 500;
        padding: 0px !important;

        &__end {
          font-size: 17px;
          color: $blue;
          font-weight: 500;
          text-align: end;
          padding: 0px !important;
        }
      }
    }

    @include responsive-mobile {
      .result {
        display: block;
        margin-top: 0px;
        margin-bottom: 25px;

        &__text {
          font-size: 15px;
          color: $blue;
          margin-bottom: 10px;
          font-weight: 500;
          padding: 0px !important;

          &__end {
            font-size: 15px;
            color: $blue;
            font-weight: 500;
            text-align: start;
            padding: 0px !important;
          }
        }
      }
    }

    @include responsive-tablet-pro {
      .result {
        display: block;
        margin-top: 0px;
        margin-bottom: 25px;
        width: 100% !important;

        &__text {
          font-size: 15px;
          color: $blue;
          margin-bottom: 5px;
          font-weight: 500;
          padding: 0px !important;
          flex: 0 0 100% !important;
          max-width: 100% !important;

          &__end {
            font-size: 15px;
            color: $blue;
            font-weight: 500;
            text-align: start;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            padding: 0px !important;
          }
        }
      }
    }

    .empty-msg-data {
      padding: 15px 15px;
      font-size: 0.9rem;
      border-radius: 5px;
      color: #828282;
      font-weight: 400;
      border-radius: 5px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    }

    .items-list {
      &__zone {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &:first-child {
          @include responsive-desktop-min {
            .title {
              margin-top: 0px;
            }
          }
        }

        .title {
          color: $blue;
          font-size: 1.2rem;
          font-weight: bold;
          text-transform: capitalize;
          margin-bottom: 10px;
        }
      }
    }

    .list {
      border-radius: 5px;
      box-shadow: 0px 4px 6px rgba(20, 70, 150, 0.15);
      background-color: white;

      .empty-msg-base {
        margin: 15px 30px;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }

  .filters {
    padding: 0px 0px 0px 0px;
    color: $blue;

    .form-group {
      margin-bottom: 1.5rem !important;
    }

    @include responsive-mobile {
      .form-group {
        margin-bottom: 1.5rem;
      }
    }

    @include responsive-tablet-low-resolution {
      .form-group {
        margin-bottom: 1.5rem;
      }
    }

    @include responsive-tablet {
      .form-group {
        margin-bottom: 1.5rem;
      }
    }

    @include responsive-desktop-min {
      display: flex;

      .form-group {
        padding: 0px;
        flex-grow: 1;
      }
    }
  }
}

.modal-text {
  margin-bottom: 15px;
  margin-top: 15px;
}