@import '@styles/settings/_colors.scss';
@import '@styles/mixins/_flex-alignment.scss';
@import '@styles/mixins/_responsive.scss';

.transfer-settlements {

  .page-title h4 {
    color: $blue-9;
    font-size: 26px;
    line-height: 30.47px;
    font-weight: 700;
    margin-bottom: 45px;
  }

  .card {
    min-height: 512px;
    max-height: 100%;
    margin-bottom: 10px;
  }

  .card-title {
    color: $blue;
    font-size: 22px;
    line-height: 26px;
    font-weight: bold !important;
  }

  .center-arrow-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 487px;
  }

  .custom-button {
    width: 100%;
    padding: 15px 0px;
    margin-bottom: 10px;
  }

  .space-rigth {
    margin-right: 10px;
  }

  .space-left {
    margin-left: 10px;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;

    .button-space {
      width: 214px;
    }
  }

  @include responsive-mobile {
    .space-down {
      margin-bottom: 20px;
    }
  }

  .filters {
    padding: 0px 0px 0px 0px;
    color: $blue;

    .form-group {
      margin-bottom: 1.5rem;
    }

    @include responsive-desktop-min {
      display: flex;

      .form-group {
        flex-grow: 1;
      }
    }
  }

  .provider-empty {
    text-align: center;
    font-size: 20px;
    color: #b3b3b3;
    font-weight: 500;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: $blue;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .provider-details {
    color: $blue;
    font-size: 16px;
    line-height: 24, 61px;
    font-weight: 700;

    &__name {
      margin: 0 !important;
    }
  }

  div.values {
    width: 100%;

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li {
      display: flex;
      justify-content: space-between;
      font-size: 0.85rem;
      padding: 3px 35px;

      &:nth-child(odd) {
        background-color: $white-1;
      }

      span {
        color: $blue-5;
      }

      span:last-child {
        font-weight: 600;
      }
    }

    &:not(:last-child) {
      ul {
        padding-bottom: 10px;
      }
    }
  }
}

.is-not-available {
  margin-top: 1.5rem;
  font-size: 15.5px;

  &_alert {
    padding: 0.5rem 0.5rem;
    margin-bottom: 0rem !important;
  }
}