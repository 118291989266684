@import '@styles/settings/_colors.scss';
@import '@styles/mixins/_responsive.scss';

.maintainers_home {
  &_title {
    color: $blue;
    font-weight: 700;
  }

  .img-desk {
    position: absolute;
  }

  .custom-button {
    width: 100%;
    padding: 0px 15px;
    margin-bottom: 15px;
    font-weight: 500;
    text-align: start;
    height: 55px;
    font-size: 16px;
  }

  @include responsive-desktop-min {
    &_title {
      font-size: 45px;
      line-height: 46.7px;
      margin-top: 107px;
      margin-bottom: 75px;
    }
  }

  @include responsive-tablet-pro {
    &_title {
      margin-top: 107px;
      margin-bottom: 75px;
    }
  }

  @include responsive-tablet {
    &_title {
      font-size: 45px;
      line-height: 46.7px;
      margin-top: 50px;
      margin-bottom: 50px;
      text-align: center;
    }

    .btn {
      width: 100%;
    }
  }

  @include responsive-tablet-low-resolution {
    &_title {
      font-size: 35px;
      line-height: 46.7px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 50px;
    }

    .btn {
      width: 100%;
    }
  }

  @include responsive-mobile {
    .card_container {
      margin-top: 10px;
    }

    .img_mobile {
      width: 100%;
    }

    &_title {
      font-size: 40px;
      line-height: 41.88px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 50px;
    }

    .btn {
      width: 100%;
    }
  }

  @include responsive-mobile-low {
    .card-body {
      padding-right: 50px !important;
    }
  }

  button.btn.Ripple-parent.btn-default.white-btn:focus {
    background: white !important;
  }

  .card_container {
    margin-bottom: 90px;

    &_routes {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .card-body {
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 20px;
        padding-right: 10px;
        max-width: 100%;
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }
}