@import '@styles/settings/_colors.scss';
@import '@styles/mixins/_responsive.scss';

.discount_prioritization {
  margin-bottom: 30px;

  &_title {
    font-size: 30px;
    line-height: 48px;
    color: $blue;
    font-weight: 400;
    margin-top: 10px;
  }

  &_subtitle {
    color: $blue;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
  }

  &_background {
    background: $secondary;
    padding-top: 2rem;
    padding-left: 4.7rem;
    padding-right: 4.7rem;

    .number {
      width: 25px;
      font-weight: 700;
      color: $blue;
    }

    .text-description {
      font-weight: 700;
      color: $blue;
      max-width: 275px;
    }
  }

  &_skeleton {
    background: $secondary;
    padding-top: 2rem;
    padding-left: 9.7rem;
    padding-right: 9.7rem;
    height: 400px;
  }

  @include responsive-tablet-low-resolution {
    &_title {
      font-size: 26px;
      line-height: 30px;
      color: $blue;
      font-weight: 400;
      padding-left: 10px;
      padding-right: 10px;
    }

    &_subtitle {
      color: $blue;
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      padding-left: 10px;
      padding-right: 10px;
    }

    margin-bottom: 0px;
    width: 100%;
    padding: 10px;

    &_background {
      background: $secondary;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  @include responsive-tablet {
    &_background {
      background: $secondary;
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }
  }

  @include responsive-mobile {
    &_title {
      font-size: 24px;
      line-height: 25px;
      color: $blue;
      font-weight: 400;
      margin-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
    }

    &_subtitle {
      color: $blue;
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      padding-left: 15px;
      padding-right: 15px;
    }

    margin-bottom: 0px;
    width: 100%;
    padding: 10px;

    &_background {
      background: $secondary;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  .custom-buttom {
    width: 170px;
    margin: 15px;
    padding: 18px 0;
  }
}