/**
* Global Site Settings
*
**/
$site-font-size: 16px;
$site-background: #F5F5F5;
$defult-border-radius: 8px;

$vertical-gradient-default: linear-gradient(180deg, #1f3e98, #5b91e6);
$sidebar-width: 0px;
$sidenav-slim-width: 0px;