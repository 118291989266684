$blue: #003C9B;
$blue-2: #314FAB;
$blue-3: #0D1F3C;  
$blue-4: #15489C;
$blue-5: #485068; 
$blue-6: #1F3E98;
$blue-7: #00B4DB;
$blue-8: #3551A2;
$blue-9: #13255B;
$blue-10: #798BC1;
$blue-ice: #4C65AD;
$light-blue: #EDF1F9;
$light-blue-2: #CDDCF6;
$white-1: #FAFAFA;
$light-blue-4: #5072A7;
$pink: #FFB5A3;
$red: #E15454;
$red-2: #CD3938;
$orange: #ED8E2F;
$yellow: #FFD600;
$green: #159700;
$gray: #DADADA;
$gray-2: #B4B4B4;
$dark-gray: #979797;
$dark-gray-2: #898989;
$dark-gray-3: #565656;
$dark-gray-4: #636363;
$black: #323848;
$secondary: #F7F7F7;
