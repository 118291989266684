@mixin flex($horizontal, $vertical) {
  display: flex;
  justify-content: $horizontal;
  align-items: $vertical;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}